body{
  font-family: SF_Pro_Display_Medium;
  box-sizing: border-box;
  font-variant: initial;
  font-feature-settings: initial;
}

:root {
  font-family: SF_Pro_Display_Medium;
}
@import "assets/css/app";
