.modal.errorPop, .commonPop {
  max-width: 400px !important;
  // width: auto !important;

}
.modal.errorPop{
  @include respond(small){
    width: auto !important;
  }
}
.ant-modal {
  padding-bottom: 0px !important;
  @include respond(small){
    margin: 8px 15px !important;
  }
  .ant-modal-content {
    border-radius: 10px;
  }
}
.modal.errorPop,.commonPop {
  .ant-modal-content {
    border-radius: 1rem;
  }
}

.delete-pop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    margin: 15px;
    font-size: 22px;
  }
.btn{
  min-width: 154px !important;
}
  .desc {
    color: #494949;
    line-height: 1.7rem;
    text-align: center;
    margin-bottom: 20px;
    text-transform: capitalize;
  }

  .action {
    @extend .d_flex;
    @extend .justify_content_between;
    @extend .align_items_center;

    .btn_primary {
      background: #C00C2D;

    }
  }
}