.img{
    max-width: 100%;
    height: auto;
}
.flex{
    display: flex;
    align-items: center;
}
.i_degn{
    border: 2px solid #FFFFFF;
    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.15));
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-right: 7px;  
    object-fit: cover;
}
.small_i{
    width: 25px;
    height: 25px;
    background: #FFFFFF;
    border: 1px solid #D6D6D6;  
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
}
.small_i:not(:first-child){
    margin-left: -10px;
}
.red_t{
    color: rgba(192, 12, 45, 1);
    text-decoration: none;
    cursor: pointer;
}
.colm_gap{
   column-gap:12.64px;
}
.heart_c{
    margin-left: 6.67px;
}
.mar_b{
    margin-bottom: 22.5px;
}
.mar_t{
    margin-top: 18px;
}
.mar_ts{
    margin-top: 16px;
}
.red_text{
    font-weight: 700;
}