$light_gray: #F8F8F8;
$light_white: #F8F8F8;
$dark_gray: #CED5DA;
$black: #000000;
$gray: #3E4A58;
$light_blue :#457B9D;
$marron :#C00C37;
$white :#FFFFFF;
$blue :#1784E9;
$text_gray: #495568;
$dark_silver:#3E4A58;
$silver_gray: #495568;
$maroon: #C00C2D;


$tablet : 768px;
$mobile : 320px;
$midium: 551px;
$small: 415px;
$extra_small: 375px;


$line-height: 1.9;
$lines-to-show: 13;