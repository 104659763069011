.orgpost_detail {
    background: $light_white;
    border-top: 1.5px solid $dark_gray;
    padding: 9px 0 10px 0;

    @media (max-width: 480px) {
        display: none;
    }

    .download_detail {
        display: flex;
        align-items: center;

        @media (max-width:$tablet) {
            flex-wrap: wrap;
        }

        .download_text {
            p {
                color: $black;
                font-size: 12px;
                line-height: 15px;
                text-transform: uppercase;
                letter-spacing: 0.02em;
                font-family: ProximaNova_SemiBold;
                margin-bottom: 0;

            }
        }

        .download_btn {
            margin: 0 0 0 19px;
            display: flex;

            @media (max-width:$tablet) {
                width: 100%;
                padding: 20px 0 20px;
                margin: 0;

            }

            .appstore {
                margin: 0 14px 0 0;

                img {
                    min-height: 36px;
                    height: 100%;
                    max-height: 36px;
                    display: block;
                }
            }

            .google_play {
                @extend .appstore;
                margin: 0 0;
            }
        }
    }
}

.cancer_support_detail {
    margin: 26px 0 95px 0;
    min-height: calc(100vh - 110px);
    @media screen and (max-width: 480px) {
        margin: 4px 0 98px;
    }
    .can_container {
        padding: 0 20px 0 20px;
        @media only screen and (max-width: 415px) {
            padding: 0 !important;
        }

    }







    .cancer_image_location {
        max-width: 450px;
        width: 100%;

        .slick-next {
            right: 14px;
            width: 24px;
            height: 24px;
            z-index: 99;
            background: rgba(62, 74, 88, 0.65);
            border-radius: 50%;

            &::before {

                opacity: unset;
                content: '' !important;
                background-image: url('../images/right_arrow.svg');
                background-repeat: no-repeat;
                width: 6px;
                height: 11px;
                display: block;
                background-position: center;
                left: 0;
                right: 0;
                margin: 0 auto;
                color: unset;
            }
        }

        .slick-prev {
            left: 15px;
            z-index: 99;
            width: 24px;
            height: 24px;
            background: rgba(62, 74, 88, 0.65);
            border-radius: 50%;

            &::before {
                opacity: unset;
                background-image: url('../images/left_arrow.svg');
                background-repeat: no-repeat;
                width: 6px;
                height: 11px;
                display: block;
                background-position: center;
                left: 0;
                right: 0;
                margin: 0 auto;
                color: unset;
            }


        }

        .slick-slide{
            img{
                display: block;
                width: 100%;
                max-width: 450px;
                height: 100%;
                min-height: 205px;
                max-height: 205px;
                object-fit: cover;
            }
        }
        .banner_post{
            position: relative;
            .cancer_profile {
                position: absolute;
                bottom: -25px;
                left: 24px;
                padding: 0 0 11px 0;

                img {
                    border: 2px solid #FFFFFF;
                    filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15));
                    border-radius: 50%;
                    width: 100%;
                    max-width: 60px;
                    height: 100%;
                    object-fit: cover;
                    min-width: 60px;
                    min-height: 60px;
                    max-height: 60px;
                }
            }

        }
        .ps_org_responsive{
            @media only screen and (max-width: 415px) {
                padding: 0 24px 0 24px;
                margin-bottom: 22px;
                margin-top: 10px;
            }
        }

        .banner_text {
            padding: 32px 0 6px 0;
            margin-top: -6px;
            @media only screen and (max-width: 415px) {
                padding: 32px 24px 6px 24px !important;
            }

            p {
                color: $gray;
                font-size: 16px;
                line-height: 20px;
                font-family: 'SF_Pro_Display_Bold';
                text-transform: capitalize;
                max-width: 442px;
                width: 100%;
                margin-bottom: 0;
            }
        }

        .banner_text.event_text{
            padding: 32px 0 16px 0;
            margin-top: -6px;
        }
        .location_text {
            display: block;

            @media only screen and (max-width: 415px) {
                padding: 0px 24px 0px 24px;
            }
            .location_address {
                max-width: 300px;
                width: 100%;
                a {
                    position: relative;
                    padding-left: 18px;
                    max-width: max-content;
                    min-width: 129px;
                    width: 100%;
                    font-family: 'SF_Pro_Display_SemiBold';
                    color: $light_blue;
                    line-height: 19px;
                    font-size: 14px;
                    margin: 0;
                    text-decoration: none;
                    display: inline-block;
                
                    &::before{
                        content: '';
                        position: absolute;
                        width: 10px;
                        height: 15px;
                        background: url(../images/location.webp) no-repeat;
                        background-size: contain;
                        left: 0;
                        top: 0;
                    }
                }
            }
        }

        .contact_detail {
            display: flex;
            max-width: 421px;
            width: 100%;
            justify-content: space-between;
            padding: 33px 0 31px 0;
            align-items: center;

            @media only screen and (max-width: 415px) {
                padding: 33px 24px 31px 24px;
            }

            .contact_button {
                max-width: 111px;
                width: 100%;

                button {
                    background: $marron;
                    border-radius: 30px;
                    padding: 17px 0 17px 0;
                    max-width: 111px;
                    width: 100%;
                    color: $white;
                    font-size: 14px;
                    line-height: 14px;
                    text-align: center;
                    text-transform: capitalize;
                    font-family: ProximaNova_Bold;
                    border: transparent;
                    position: relative;
                    padding-left: 20px;
                    cursor: pointer;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 16px;
                        bottom: 17px;
                        background-image: url('../images/mali.webp');
                        background-repeat: no-repeat;
                        width: 18.33px;
                        height: 14.67px;
                        margin-right: 9px;
                        background-size: 100% 100%;
                    }
                }
            }

            .upload_icon {
                span {
                    margin: 0 19px 0 0;
                    cursor: pointer;

                    &:last-child {
                        margin: 0 0px 0 0;
                    }

                    img {
                        max-width: 20px;
                        width: 100%;
                    }

                }
            }
        }
//.blog_text.event_blog_text{
//    padding: 0 0 31px 0;
//    @media only screen and (max-width: 415px) {
//        padding: 0px 24px 31px 24px;
//    }
//}
        .blog_text {
            padding: 0 0 24px 0;

            @media only screen and (max-width: 415px) {
                padding: 0px 24px 24px 24px;
            }

            p {
                font-size: 14px;
                line-height: 19px;
                color: #495568;
                font-family: SF_Pro_Display_Regular;
                margin-bottom: 18px;

                &:last-child {
                    margin-bottom: 0px;
                }


            }
        }

        .visit_website_text {
            @media only screen and (max-width: 415px) {
                padding: 0px 24px 0 24px;
            }

            a {
                color: $blue;
                font-size: 13px;
                line-height: 15px;
                font-family: 'SF_Pro_Display_SemiBold';
                text-decoration: underline;
                text-transform: capitalize;


            }
        }

    }
}

.cancer_support_detail.event_main {

    .banner_text {
        padding: 32px 0 16px 0;
        margin-top: -6px;
        @media only screen and (max-width: 415px) {
            padding: 32px 24px 6px 24px !important;
        }
    }

    .blog_date_time {
        padding: 0 0 8px 0;

        @media only screen and (max-width: 415px) {
            padding: 0px 24px 6px 24px;
        }

        p {
            font-family: 'SF_Pro_Display_Bold';
            font-size: 14px;
            line-height: 19px;
            text-transform: capitalize;
            color: $marron;
            margin-bottom: 0;
        }
    }

    .organized_text {
        padding: 25px 0;
        width: 100%;

        @media only screen and (max-width: 415px) {
            padding: 18px 24px 38px 24px;
            max-width: 100%;
        }

        p {

            font-family: 'SF_Pro_Display_SemiBold';
            font-size: 14px;
            line-height: 19px;
            text-transform: capitalize;
            color: $text_gray;
            margin-bottom: 0;
            @media only screen and (max-width: 415px) {
         font-size: 14px;
                line-height: 19px;
            }

            u {
                display: block;
                font-family: 'SF_Pro_Display_SemiBold';
            }

        }
    }

    .contact_detail {
        padding: 0 0 31px 0 !important;

        @media only screen and (max-width: 415px) {
            padding: 0px 24px 31px 24px !important;
        }
    }
    .contact_detail.event_contact_details{
        justify-content: normal;
        .upload_icon{
            margin-left: 19px;
        }
    }

}
.post-description-main{
    // white-space: pre-wrap;
}

.ps_org_details.ps_org_responsive{
    .ps_profile_detail{
        .ps_main_detail{
            margin-top: 0;
            margin-bottom: 22px;
            @media only screen and (max-width: 415px) {
                margin-top: 0 !important;
            }

            .followers_left{
                margin-right: 6px;
                .total_followers{
                    margin-bottom: 0 !important;
                    margin-top: 3px !important;
                }
            }
        }
    }

    h4{
        margin-bottom: 0 !important;
    }

}
.ps_org_responsive .ps_profile_detail .ps_main_detail .ps_followers{
    font-size: 14px !important;
    line-height: 17px !important;
}

//.cg_post_data.post_details_cg{
//    .main_content .first_article {
//        h4,.f_detail .cg_postUser_detail .can_head{
//            font-family: SF_Pro_Display_Bold;
//        }
//        .f_detail .side_detail .para_detail p{
//            font-family:SF_Pro_Display_Regular ;
//        }
//        .f_detail .cg_postUser_detail {
//            .para_head{
//                font-family:SF_Pro_Display_Regular ;
//            }
//            .time_d span{
//                font-family: SF_Pro_Display_SemiBold;
//            }
//        }
//        //    font-family:SF_Pro_Display_Regular ;
//    }
//}