.physician_post_data{
    margin-top: 21px;
    font-family:SF_Pro_Display_Regular ;
    letter-spacing: 0.02em;

    .ps_main_content{
        align-items: flex-start;
        margin: 4.5px 0 0 0;
        max-width: 307px;
        .sec_ps_profile{
            display: flex;
            margin-bottom: 26px;
            img{
                @include big_icon;
                height: 80px;
                width: 80px;
                margin-right: 11px;
            }
            .ps_profile_detail{
                .user_name{
                    // @include heading;
                    color: $text_gray;
                    line-height: 17px;
                    font-family: SF_Pro_Display_Bold;
                    margin: 0;
                    font-size: 14px;
                }
                .ps_main_detail{
                    margin-top: 7px;
                    margin-bottom: 13px;
                    .ps_followers{
                        line-height: 16px;
                        font-family: SF_Pro_Display_Medium;
                        color: $text_gray;
                        font-size: 13px;
                        &:first-child{
                            margin-right: 25px;
                            margin-bottom: 0;
                        }
                    }
                    .total_followers{
                        margin-top: 0px;
                        line-height: 17px;
                        color: $text_gray;
                        font-family: SF_Pro_Display_Bold;
                        margin-bottom: 0;
                    }
                }
                .ps_main_btn{
                    button{
                        @include btn_red;
                    }
                    .btn_follow{
                        max-width: 76px;
                        width: 100%;
                        margin-right: 10px;
                    }
                    .btn_message{
                        max-width: 80px;
                        width: 100%;
                    }
                }
            }
        }
        .sec_user_allphotos{
            margin: 0px 0 26px;
            .all_photos{
                position: relative;
                img{
                    border: 1px solid #CED5DA;
                    border-radius: 10px;
                    height: 50px;
                    width: 50px;
                    object-fit: cover;
                    display: inline-block;
                    &:nth-child(2){
                        position: absolute;
                        left: 15px;
                        top: 0;
                    }
                    &:nth-child(3){
                        position: absolute;
                        left: 30px;
                        top: 0;
                    }
                    &:nth-child(4){
                        position: absolute;
                        left: 45px;
                        top: 0;
                    }
                }
            }
            .all_photos.all_photos_org{
                img{
                    border-radius: 50%;
                }
            }
            .more_photos{
                display: block;
                margin: auto 0 auto 63px;
                p{
                    font-family: SF_Pro_Display_SemiBold;
                    color: $text_gray;
                    font-size: 12px;
                    line-height: 15px;
                   margin-bottom: 4px;
                }
                .dots{
                    .dot{
                        height: 4px;
                        width: 4px;
                        border-radius: 100%;
                        background-color: $gray;
                        display: block;
                        margin-right: 2px;
                    }
                }
            }
        }
        .ps_bio_alldetail{
            .bio_main_title{
                font-family: SF_Pro_Display_Bold;
                color: $text_gray;
                margin-bottom: 5px;
            }
            .sub_title{
                font-family: SF_Pro_Display_SemiBold;
                color: $text_gray;
                line-height: 19px;
            }
            .bio_detail{
                color: $text_gray;
                .phy_bio_wrapper{
                    line-height: 19px;
                    font-family:SF_Pro_Display_Regular ;
                    font-size: 14px;
                    color: #495568;
                }
            }
            .View_more{
                @include View_more;
                cursor: pointer;
                margin: 0;
            }
        }
    }
    .post_main_content{
        align-items: flex-start;
        margin: 31px 0 0 0;
        max-width: 327px;
        .first_article{
            position: relative;
            @include first_detail;
            display: unset;
            //  @include f_article;
            .main_postarticle_wrapper{
                .f_detail{
                    .cg_post_profile{
                        &::after{
                            content: none;
                        }
                        @include share_profile;
                        .cg_postUser_detail{
                            padding: 0px 0 0 10px;
                            .can_head{
                                line-height: 18px;
                            }
                        }
                    }
                    .side_detail{
                        @include side_detail_membership;
                        .cg_post_slider{
                            max-width: 100%;
                            width: 100%;
                            border-radius: 10px;
                            height:100%;
                            object-fit: cover;
                        }
                    }
                }
            }
            .main_comments_wrapper{
                @include first_detail;
                .cg_post_profile{
                    width: auto;
                    &::after{
                        &::after{
                            content: '666';
                        }
                    }
                }
                .side_detail{
                    .cg_postUser_detail{
                        @include share_profile;
                    }
                    @include side_detail_membership;
                    .icon_content {
                        margin-bottom: 17px;
                    }
                    .para_detail{
                        img{
                            width: 100%;
                            border-radius: 10px;
                            height: 272px;
                            object-fit: cover;
                        }
                    }
                }
            }
            .view_all_sec {
                margin-left: 9px;
                align-items: center;
                .btn_view_all {
                  margin-left: 11px;
          
                  button {
                    background-color: #457B9D;
                    border: none;
                    border-radius: 30px;
                    color: #FFFFFF;
                    padding: 7px 14px;
                    font-family: SF_Pro_Display_Medium;
                    font-size: 13px;
                    width: 100%;
                    cursor: pointer;
                    line-height: 15px;
                    letter-spacing: 0.02em;
                    cursor: pointer;
                  }
          
                  &.view_all_comment {
                    margin-left: 24px;
                    // margin-top: 20px;
                  }
                }
              }
        }
        .View_more{
            cursor: pointer;
            @include View_more;
            margin-top: 38px;
        }
    }
}
.app_dwn_section_last{
    .fourth_article{
        background: #FFFFFF;
        max-width: 326px;
        width: 100%;
        padding: 22px 0px 50px;
        display: block !important;
        .f_head{
            font-size: 14px;
            line-height: 14px;
            color: #495568;
            font-family: ProximaNova_Bold;
            color: #457B9D;
            text-align: center;
        }
        .mobile_app {
            max-width: 262px;
            width: 100%;
            margin: auto;
            .dwn_head {
                margin-bottom: 9px;
                text-align: center;
                font-family: ProximaNova_SemiBold;
                font-size: 12px;
                line-height: 15px;
                letter-spacing: 0.02em;
                color: #000000;
            }
    
            .store_flex {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
    
                img {
                    height: 36px;
                    margin: 4px 0;
    
                    &:first-child {
                        margin: 4px 15px 0 0;
                    }
                }
            }
    
        }
    }

}

.ps-details_biodetail-mr {
    @include width(530px);
    padding: 0;

    p {
        font-family: ProximaNova_Regular;
        font-size: 13px;
        line-height: 17px;
        color: #000000;
        // white-space: pre-line;
        .phy_bio_wrapper:not(:last-child) {
            margin: 0 0 14px;
        }

        &:not(:last-child) {
            padding-bottom: 15px;
            margin: 0;
        }
    }
}
.ps_org_details{
    margin: 10px 0 28px 0;
    display: flex;
    align-items: end;
    .followers_left{
        margin-right: 6px;
    }
    .dots {
        width: 28px;
        height: 28px;
        background: #E5E5E5;
        display: flex;
        align-items: center;
        border-radius: 50%;
        margin-left: 19px;
        justify-content: center;
        .dot {
            height: 4px;
            width: 4px;
            border-radius: 100%;
            background: rgba(73, 85, 104, 0.6);
            display: block;
            margin-right: 0;
            &:nth-child(2){
                margin: 0 2px;
            }
        }
    }
    .dots.d_flex.heart_images.justify_content_center{
        background: transparent;
        margin-left: 21px;
        img{
            max-width: 20px;
            width: 100%;
            cursor: pointer;
        }
    }
    .ps_profile_detail{
        .user_name{
            // @include heading;
            color: $text_gray;
            line-height: 17px;
            font-family: SF_Pro_Display_Bold;
            margin: 0;
            font-size: 14px;
        }
        .ps_main_detail{
            margin-top: 8px;
            .ps_followers{
                line-height: 16px;
                font-family: SF_Pro_Display_Medium;
                color: $text_gray;
                font-size: 13px;
                &:first-child{
                    margin-right: 25px;
                    margin-bottom: 0;
                }
            }
            .total_followers{
                margin-top: 0px;
                line-height: 17px;
                color: $text_gray;
                font-family: SF_Pro_Display_Bold;
            }
        }
        .ps_main_btn{
            button{
                @include btn_red;
            }
            .btn_follow{
                max-width: 76px;
                width: 100%;
                margin-right: 10px;
            }
            .btn_message{
                max-width: 80px;
                width: 100%;
            }
        }
    }
}
.slick-dots {
    bottom: -20px;

    li{
        width: 4px;
    }
}
.slick-dots li.slick-active button:before {
    opacity: 1;
    color: #495568;
}
