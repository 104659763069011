.main_herader_row {
  width: 100%;
  padding: 11px 30px 10px 36px;
  margin: 0;

  &.crowdkure_header_row {
    padding: 16px 12px 14px 37px;

    .header_profile {
      width: auto;
    }
  }
}
.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0;

  .logo,
  .crowdkure_logo {
    padding-right: 10px;
    line-height: 0;
    @include width(287px);
    cursor: pointer;

    img {
      @include image;
      width: 100%;
      display: block;
      max-width: 97px;
      height: 70px;

    }
  }

  .crowdkure_logo {
    max-width: 100%;
    width: auto;
  }

  .header_profile {
    width: calc(100% - 287px);
    // max-width: 40rem;
    padding-left: 10px;
    @extend .d_flex;
    @extend .align_items_center;
    @extend .justify_content_end;
    line-height: 0;

    .profile {
      @extend .d_flex;
      @extend .align_items_center;

      &_name {
        @extend .fs_14;
        line-height: 2rem;
        @extend .text_uppercase;
        color: $black;
        // @extend .fw_600;
        @extend .mr_1;
        display: inline-block;
        // width: calc(100% - 62px);
        text-align: right;
      }

      &_pic {
        @extend .mr_1;
        height: 42px;
        @include width(42px);
        @extend .img_object_center;
        border: .25rem solid $white;
        @extend .radius_rounded;
        filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.3));
        @extend .cursor_pointer;

        img {
          height: 100%;
          @include width(100%);
          display: block;
          @extend .radius_rounded;
          object-fit: cover;
        }
      }
    }

    .drop_down {
      @extend .mr_2;
      @extend .position_relative;

      &_list {
        @extend .position_absolute;
        min-width: 18.4rem;
        background: $white;
        top: 3rem;
        right: -2rem;
        @extend .radius_10;
        padding: 2.5rem 2rem;
        z-index: 85;
        box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.15);
        z-index: 9;

        //&::before {
        //  content: '';
        //  background: url(../images/drop_down_ceross_icon.png) no-repeat;
        //  width: 1.3rem;
        //  height: 1.3rem;
        //  display: block;
        //  position: absolute;
        //  top: -.7rem;
        //  right: 1.8rem;
        //}

        &.notification_modal {
          min-width: 325px;
          padding: 20px 20px 17px 17px;
          min-height: auto;
          height: auto;
          max-height: 326px;
          overflow-y: auto;
          margin: 0;
          z-index: 999;

          &::before {
            display: none;
          }

          li {
            font-family: "Roboto", sans-serif;
            font-weight: 500;
            font-size: 11px;
            line-height: 15px;
            color: #000000;
            text-align: left;
            padding-bottom: 15px;
            margin: 0;
            border-bottom: 1px solid #F1F1F1;

            &:not(:first-child) {
              padding: 15px 0;
            }

            &:last-child {
              border: unset;
              padding-bottom: 0;
            }

            a {
              @extend li;
              text-decoration: underline;
              font-weight: 600;
            }

            &.notification_modal_flex {
              align-items: flex-start;
            }
          }
        }

        li {
          @extend .text_right;
          @extend .mb_1;
          @extend .position_relative;

          a {
            @extend .fs_12;
            line-height: 1.5rem;
            color: $text_gray;
            @extend .montserrat_semibold;

            &.active {
              color: $black;

              span {
                @extend .d_inline_block;
                @extend .position_absolute;
                top: 50%;
                transform: translateY(-50%);
                right: -10px;
              }
            }
          }
        }

        li:last-child {
          margin-bottom: 0;
        }
      }
    }

    .notification_block {
      position: relative;
      cursor: pointer !important;

      img {
        width: 2.4rem;
        height: 2.4rem;
        @extend .img_object_center;
      }

      span {
        font-weight: 600;
        font-size: 9px;
        line-height: 12px;
        letter-spacing: 0.3125px;
        text-transform: capitalize;
        color: #FFFFFF;
        background: #F84747;
        border: 1px solid #FFFFFF;
        box-sizing: border-box;
        min-width: 18px;
        // height: 18px;
        padding: 2px;
        display: block;
        text-align: center;
        border-radius: 100%;
        position: absolute;
        bottom: -2px;
        right: -8px;
        cursor: pointer;
        display: grid;
        place-items: center;
      }
    }
  }

  //.main_notification_modal {
  //  &::after {
  //    content: "";
  //    background: url(../images/drop_down_ceross_icon.png) no-repeat;
  //    width: 1.3rem;
  //    height: 1.3rem;
  //    display: block;
  //    position: absolute;
  //    top: 34px;
  //    right: 0.8rem;
  //    margin-top: -1px;
  //    z-index: 9;
  //  }
  //}
}

.psDetail_main_header {
  margin-left: auto;
  width: calc(100% - 250px);

  @media (max-width: 1200px) {
    margin: 0 auto;
    width: 100%;
  }

  .container_fluid.ps_detail_header {
    max-width: 815px;
    width: 100%;
    padding: 20px 10px 10px;

    @media (max-width: 1200px) {
      margin: 0 auto;
    }

    .main_herader_row {
      margin: 0;
      padding: 0;
      width: 100%;

      .header.physician_detail_logo.px_auto {
        padding: 0;

        .header .logo {
          padding: 0;
        }
      }
    }
  }
}

.ps-details_main_block {
  padding: 10px 20px;
  max-width: 1374px;
  width: 100%;
  margin: 0 auto;
  min-height: calc(100vh - 143px);


  @media (max-width: 1440px) {
    max-width: 1404px;
    padding: 10px 20px 0 52px;
    .ps-details_container{
      margin: 0 auto 0 0;
    }
  }
  @media (max-width: 1200px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    padding: 0;
  }
  @media (max-width: 680px) {
    min-height: calc(100vh - 245px);
  }
  

  .ps-details_container {
    @include width(762px);

    .ps-details_header_main {
      padding: 10px 20px;
      background: rgba(154, 176, 189, 0.19);
      border: 1px solid rgba(0, 0, 0, 0.2);

      @media (max-width: 768px) {
        padding: 20px 10px;
        background: unset;
        border: unset;
      }

      @media (max-width: 350px) {
        padding: 10px 0;
      }
    }

    .ps-details_header {
      width: 100%;
      margin: 0 auto;
      @include flex;

      .ps-details_title {
        h2 {
          font-family: ProximaNova_Bold;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.3125px;
          text-transform: uppercase;
          color: #000000;
          padding-right: 10px;
          margin: 0;

        }
      }

      .ps-details_profile {
        display: flex;
        align-items: center;
        max-width: 400px;
        width: 100%;
        @media screen and (max-width: 768px) {
          max-width: 100%;
        }
      }

      .ps-details_name {
        width: calc(100% - 50px);
        padding-right: 16px;

        h4 {
          font-family: ProximaNova_Bold;
          font-size: 16px;
          line-height: 19px;
          text-align: right;
          color: #495568;
          text-transform: unset;
          margin: 0;
        }

        span {
          display: block;
          font-family: ProximaNova_Medium;
          font-size: 12px;
          line-height: 14px;
          text-align: right;
          color: #7C7C7C;
          @include width(150px);
          margin: 8px 0 0 auto;
        }
      }

      .ps-details_picture {
        @include width(50px);
        height: 50px;

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50px;
        }
      }

      @media (max-width: 768px) {
        & {
          display: block;

          .ps-details_title {
            display: none;
          }
        }
      }

      @media (max-width: 350px) {
        & {
          .ps-details_profile {
            flex-direction: column-reverse;

            .ps-details_name {
              padding: 10px 0 0 0;

              h4,
              span {
                text-align: center;
                margin: 5px auto 0;
              }
            }
          }
        }
      }
    }

    .ps-details_biomain {
      @include width(100%);
      padding: 20px 26px 16px 0;


      .ps-detail_bioheader {
        @include flex;

        h4 {
          font-family: ProximaNova_Bold;
          font-size: 14px;
          line-height: 16px;
          color: #495568;
        }

        .download-btn {
          display: none;
          border: none;
          background-color: unset;
          padding: 0 0 0 10px;
          cursor: pointer;
          img {
            @include width(16px);
            height: 20px;
            display: block;
          }
        }
      }

      .ps-details_biodetail {
        @include width(530px);
        padding: 12px 0 15px;

        p {
          font-family: ProximaNova_Regular;
          font-size: 13px;
          line-height: 17px;
          color: #000000;
          // white-space: pre-line;
          .phy_bio_wrapper:not(:last-child) {
            margin: 0 0 14px;
          }

          &:not(:last-child) {
            padding-bottom: 15px;
          }
        }
      }

      .year_experiance {
        @extend .comman_title_style;
        padding-bottom: 18px;
      }

      .ps-details_educationmain {
        &:not(:last-child) {
          margin-bottom: 16px;
        }

        h4 {
          @extend .comman_title_style;
          text-transform: unset;
          padding-bottom: 7px;
        }

        .ps-details_list {
          margin-top: 0;

          ul {
            margin-bottom: 0; 
            li {
              font-family: ProximaNova_Regular;
              font-size: 12px;
              line-height: 15px;
              color: #000;
              margin: 0 !important;
              padding-left: 10px;
              @include width(280px);
              position: relative;
              &:empty::before {
                display: none !important;
              }
              &::before {
                content: '';
                position: absolute;
                width: 4px;
                height: 4px;
                background-color: #3E4A58;
                top: 5px;
                display: block;
                border-radius: unset;
                left: 0;
              }

              &:not(:last-child) {
                margin-bottom: 6px;
              }
            }
          }

        }
      }

      @media (max-width: 768px) {
        & {
          padding: 22px 25px 40px;
          max-width: 100%;
        }
      }
    }
  }
}

.copy_url_msg {
  text-align: right;
  margin-top: 10px;

  span {
    color: red;
    text-transform: capitalize;
  }
}

.comman_title_style {
  font-family: ProximaNova_Bold;
  font-size: 14px;
  line-height: 16px;
  color: #495568;
  display: block;
  text-align: left;
  margin: 0;
}

.physician_detail_logo {
  img {
    height: auto !important;
  }
}
.ps-details_list li {
  list-style: none;
}