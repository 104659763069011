@mixin container {
  max-width: 1374px;
  margin: 0 auto;
  padding: 0 20px;
  @include respond(small){
    padding: 0;          
  } 
}
@mixin img {
  max-width: 100%;
  height: auto;
}
@mixin full_flex {
  display: flex;
  align-items: center;
}

@mixin heading {
  font-size: 14px;
  line-height: 18px;
  color: #495568;
  font-family: SF_Pro_Display_Bold;
  //font-family: ProximaNova_Bold;
  margin: 0;
}
@mixin small_text {
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  color: #495568;
}
@mixin small {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #3e4a58;
}
@mixin big_icon {
  border: 2px solid #ffffff;
  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15));
  width: 45px;
  height: 45px;
  border-radius: 50%;
  // margin-right: 10px;
  object-fit: cover;
}

@mixin side_detail {
  width: calc(100% - 45px);
  padding: 4px 0 0 10px;

  .title_img_wrapper {
    max-width: 100%;
    width: 100%;
    display: block;
    height: 100px;
    margin: 0 0 10px;
    img {
      max-width: 100%;
      max-height: 100%;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
  }
}
  .para_detail {
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    //margin-bottom: 8px;
    &.post_desc{
      font-size: 14px;
      line-height: 19px;
      font-weight: 400;
      font-family:SF_Pro_Display_Regular ;
      letter-spacing: 0.02em;
      color: #12151A;
    }
    p{
      font-family:SF_Pro_Display_Regular ;
      //font-family: ProximaNova_Regular;
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 17px;
      font-family: SF_Pro_Display_Regular;
    }
  }
  .icon_content {  
    justify-content: space-between;
    small {
      @include small;
      color: #457b9d;
      font-family: SF_Pro_Display_SemiBold;
      //font-family: ProximaNova_SemiBold;
    }
    .heart_count {
      margin: 0 12.64px 0 6.67px;
      // &.post_like_cnt{
      //   font-size: 11px;
      // }
    }
    .comt_count {
      margin: 0 19px 0 7.67px;
    }
    span {
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      text-transform: capitalize;
      color: #457b9d;
      font-family: SF_Pro_Display_SemiBold;
      //font-family: ProximaNova_SemiBold;
      &.post_duration{
        font-family: SF_Pro_Display_SemiBold;
        font-size: 14px;
        line-height: 17px;
        font-weight: 600;
        color: $text_gray;
        width: 54px;
        margin-bottom: 0 !important;
      }
    }
    button{
      background: #C00C2D;
      border-radius: 20px;
      padding: 6.5px 14.1px;
      border: none;
      outline: none;
      font-family: SF_Pro_Display_Medium;
      font-size: 14px;
      line-height: 15px;
      color: #E5E5E5;
      letter-spacing: 0.02em;
      font-weight: 500;
      text-transform: capitalize;
      cursor: pointer;
      &.Follow_btn{
        margin-left: 13px;
        padding: 6.5px 10.2px;
      }
    }
  }
  .icon_content{
    margin-bottom: 15px;
    // &:not(:last-child){
    //   margin-bottom: 14.64px;
    // }

    &.like_follow_btn{
      // margin-bottom: 32.5px;
      margin-bottom: 28px;
      &.like_follow_btn_padding{
        margin-bottom: 25px !important;
      }
    }
    img{
      cursor: pointer;
    }
    .like_content{
        .like_by{
          margin-bottom: 0 !important;
          .like_grp{
            margin-top: 0 !important;
          }
        }
        &.like_comment_width{
          max-width: 140px;
          width: 100%;
          // justify-content: space-between;
          img{
            // width: 16px;
            // height: 16px;
            &:nth-child(2){
              margin: 0 16px 0 18px;
            }
          }
          p
          {
            font-family: SF_Pro_Display_SemiBold;
            font-size: 14px;
            line-height: 17px;
            font-weight: 600; 
            color: $text_gray;
            margin-bottom: 0 !important;
          }
        }
      }
    }
  .like_by {
    margin-bottom: 15px;
    small {
      @include small;
      color: #457b9d;
    }
    .like_grp {
      margin-top: 8px;
    }
  }
  .comment_box{
    margin-bottom: 19px;
    display: flex;
    align-items: center;
    &.comment_like_reply{
      margin-bottom: 18px;
    }
    small{
      padding: 0 13px 0 7px;
      &.cmnt_cnt_text{
        padding-left: 0 !important;
      }
    }
    small,span{
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #457b9d;
      font-family: SF_Pro_Display_SemiBold;
    }
  }
  .load_more_commments_btn{
    text-align: right;
    padding: 0 0 12px 0;
    &.see_more{
      margin: 5px 0;
    }
    span{
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #457b9d;
      font-family: SF_Pro_Display_SemiBold;
      cursor: pointer;
    }
  }
  .inner_comment_post{
    display: flex;
    &.profile_inner_comment{
      display: unset !important;
    }
    .inner_profile_margin{
      margin: unset !important;
    }
    .user_profile_info{
      width: calc(100% - 45px);
    }
    .time_d{
      max-width: 229px !important;
    }
  }
}
@mixin first_detail {
  display: flex;
  .b_icon {
    @include big_icon;    
  }
  .s_icon {
    @include big_icon;
    width: 36px;
    height: 36px;
    // margin: 0 auto;
    display: block;
    // margin-right: 15px;
  }
  .side_detail {
    @include side_detail;
  }
  .cg_postUser_detail{
    .can_head{
      @include heading;
      margin-bottom: 5px;
    }
    .para_head {
      @include small_text;
      margin-bottom: 0px;
      font-family: SF_Pro_Display_Medium;
      //font-family:SF_Pro_Display_Regular ;
      //font-family: ProximaNova_Regular;
    }
    .time_d {
      @include full_flex;
      margin-bottom: 9px;
      justify-content: space-between;
      max-width: 274px;
      width: 100%;
      &.profile_time_d{
        max-width: 266px !important;
      }
      img{
        cursor: pointer;
      }
      span {
        @include small;
        font-family: SF_Pro_Display_SemiBold;
        //font-family: ProximaNova_SemiBold;
        color: $text_gray;
      }
    }
  }
  .side_detail:not(:last-child) {
    margin-bottom: 17px;
  }
}

// -----------1st colum-------------
@mixin f_article {
  margin-top: 7px;
  h4 {
    @include heading;
  }
  .f_detail {
    @include first_detail;
  }

  .side_tow{
    position: relative;
    margin-top: 18px;
  }
  .side_tow{
    &::after{
      position: absolute;
      content: "";
      bottom: 0;
      left: 20px;
      width: 1px;
      height: 180px;
      background: #CED5DA;
      top: 33px;        
    }  
  }
  @include respond(tablet){
    max-width: 374px;
    margin: 6px 0 28px 0;
    padding: 0 24px 0 24px;  
    &.showmore_article{
      margin: 6px 0 40px 0;
    }          
}  
}

//----------------second colum--------------
@mixin s_article {
  padding: 0;
  background: $light_gray;
  .can_second_head {
    color: #457B9D;
    text-align: center;
    font-family: ProximaNova_Bold;
    font-size: 14px;
    line-height: 14px;
    color: #457B9D;
    padding: 0 0 10px;
    margin: 0;
  }
  .f_detail {
    @include first_detail;
    position: relative;
    padding: 28px 23px 0 24px;
    @include respond(small){
      padding: 30px 28px 0 20px;

    }
    .side_detail {
      h4 {
        text-align: left;
        font-family: SF_Pro_Display_Bold;
        //font-family: ProximaNova_Bold;
        color: #495568;
        font-size: 14px;
        line-height: 14px;
        margin: 0 0 5px;
      }
      .time_d {
        @include small;
        font-family: SF_Pro_Display_SemiBold;
        //font-family: ProximaNova_SemiBold;
        margin: 4px 0 11px 0;
      }
    }  
    &:first-child{
      padding: 16px 23px 0 24px;
    }
  }
  
  .f_deta{ 
      padding-bottom: 0;
    } 
  .f_deta{
    &::after {
      content: "";
      position: absolute;
      width: calc(100% - 44px);
      height: 0.5px;
      background: #495568;
      margin: 0 auto;
      left: 0;
      right: 0;
      bottom: 0;
    }
    &:last-child{
        &::after{
            content: unset;
        }
    }
  }
  .see_more {
    padding: 10px 0 0 24px;
    cursor: pointer;
    a{
      font-size: 13px;
      line-height: 17px;     
      color: #c00c2d;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;
      // font-family: SF_Pro_Display_Bold;
      font-family: ProximaNova_Bold;
      //margin-left: 262px;
    }  
    // @include respond(small){
    //   display: none;
    // }  
  }
  .l_detail {
    padding: 16px 30px 15px 20px;
    margin-top: 0;
    background: #457b9d;
    @include respond(small){
      margin-top: 22.5px;                 
    }  
    &.promoted_detail{
      margin-top: 22.5px;
    }
    p{
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.05em;
      color: #ffffff;
      width: 100%;
      font-family:SF_Pro_Display_Regular ;
      //font-family: ProximaNova_Regular;
    }
  }
  @include respond(tablet){
    max-width: 374px;                 
  }  
}
@mixin footer_font {
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #000000;
}
//-----------------footer css-------------
@mixin footer {
  .footer_line {
    padding: 24px 0 23px 0;
    @include respond(small){
    padding: 29px 0 29px 0;
    }
    .big_logo {
      max-height: 71px;
      @include respond(midium){
        width: 80px;
        height: 57.68px;
        display: block;
        margin: auto;
        margin-bottom: 8.32px;           
       }
    }
    .footer_heading {
      max-width: 680px;
      justify-content: space-between;
      @include respond(midium){
       flex-direction: column;
       row-gap: 38px;           
      } 
      @include respond(small){
        row-gap: 20px;
        }
      .f_head {
        @include footer_font;
        font-weight: 600;
      }
      .copy_right {
        @include footer_font;
        font-weight: 400;
        text-decoration: none;
      }
    }
  }
}

//-----------for responsive--------
@mixin respond($breakpoint) {
    
  @if $breakpoint==tablet {


    @media only screen and (max-width: 768px) {
      @content;
    }
  }

  @if $breakpoint==midium {
      @media only screen and (max-width: 566px) {
        @content;
      }
    }
    @if $breakpoint==small {
      @media only screen and (max-width: 415px) {
        @content;
      }
    }

  @if $breakpoint==mobile {
    @media only screen and (max-width: 360px) {
      @content;
    }
  }
}   


@mixin third_article {
  display: none;
  margin-bottom: 2px;
  @include respond(midium){
      width: 100%;
      padding: 17px 20px 16px;
      background: $light_gray;
      display: block;   
.mobile_app{                
     max-width: 262px;
     width: 100%;
     margin: 0 auto;
     .dwn_head{
      margin-bottom: 9px;
      text-align: center;
       font-family: ProximaNova_SemiBold;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.02em;
      color: #000000;  }        
      .store_flex{
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          img{
              height: 36px;
              margin: 4px 0;
              &:first-child{
                margin: 4px 15px 0 0;
              }
          }
      }             
  
} 
}
  
}

@mixin flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
// Font Size
.fs_9 {font-size: .9rem;}
.fs_12 {font-size: 1.2rem;}
.fs_13 {font-size: 1.3rem;}
.fs_14 {font-size: 1.4rem;}
.fs_20 {font-size: 2rem;}
.fs_28 {font-size: 2.8rem;}


// font Weight
// .fw_600{ @extend .montserrat_bold;}
// .fw_500{ @extend .montserrat_semibold;}
// .fw_400{ @extend .montserrat_light;}
// .fw_300{ @extend .montserrat_thin;}
@mixin flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin image {
  max-width: 100%;
  width: 100%;
  display: block;
}
@mixin transition($property){
  -webkit-transition: $property; /* Safari & Chrome */
  -moz-transition:    $property; /* Firefox */
  -ms-transition:     $property;
  -o-transition:      $property; /* Opera */
  transition:         $property;
}
@mixin width($maxWidth) {
  max-width: $maxWidth;
  width: 100%;
}
// Image
.img_object_center{object-fit: cover; object-position: center;}
.img_fluid{max-width: 100%;}



@mixin btn_red{
  background-color: $maroon;
  border: none;
  border-radius: 30px;
  color: $white;
  padding: 5px 0;
  font-family: SF_Pro_Display_Medium;
  cursor: pointer;
}

//-----------share membership profile--------
@mixin View_more {
  font-family: SF_Pro_Display_Bold;
  color: $maroon;
}
@mixin share_profile{
  .para_head{
    font-family: SF_Pro_Display_Medium;
    font-size: 14px;
    line-height: 17px;
  }
  .time_d{
    margin-right: 30px;
    margin-bottom: 6px;
    span{
        font-family: SF_Pro_Display_SemiBold;
        font-size: 14px;
        line-height: 17px;
        color: $text_gray;
    }
  }
}
@mixin side_detail_membership {
  width: 100%;
  padding:0;
  .para_detail{
      font-size: 14px;
      line-height: 19px;
      color: #12151A;
    margin-bottom: 12px;
      p{
          font-family: SF_Pro_Display_Regular;
          span{
              font-family: SF_Pro_Display_Bold;
              color: $maroon;
          }
      }
  }
  .icon_content {
    margin-bottom: 25px;
    &.like_comment_width{
      max-width: 50px;
      width: 100%;
      justify-content: space-between;
      img{
        width: 20px;
        height: 20px;
      }
    }
      small,span{
          font-family: SF_Pro_Display_SemiBold;
          font-size: 14px;
          line-height: 17px;
      }
  }
}

@mixin _view_more_btn{
  font-family: SF_Pro_Display_Medium;
  font-size: 13px;
  line-height: 15px;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  padding: 6px 17px;
  background: $light_blue;
  border-radius: 20px;
  border: none;
  outline: none;
}