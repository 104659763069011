// @font-face {
//     font-family: montserrat_thin;
//     src: url('../font/montserrat_thin.ttf');
// }

// @font-face {
//     font-family: montserrat_light;
//     src: url('../font/montserrat_light.ttf');
// }

// @font-face {
//     font-family: montserrat_regular;
//     src: url('../font/montserrat_regular.ttf');
// }

// @font-face {
//     font-family: montserrat_medium;
//     src: url('../font/Montserrat-Medium.ttf');
// }

// @font-face {
//     font-family: montserrat_semibold;
//     src: url('../font/montserrat_semibold.ttf');
// }

// @font-face {
//     font-family: montserrat_bold;
//     src: url('../font/montserrat_bold.ttf');
// }

@font-face {
    font-family: ProximaNova_Regular;
    src: url('../font/ProximaNova-Regular.ttf')  format('truetype');
            //url('../font/ProximaNova-Regular.woff2') format('woff2');
    //, url('../font/ProximaNova-Regular.ttf')  format('truetype'); /* Safari, Android, iOS */
     font-weight: 400;
     font-style: normal;
  }



@font-face {
   font-family: ProximaNova_Medium;
   src: url('../font/ProximaNova-Medium.ttf')  format('truetype');
           //url('../font/ProximaNova-Medium.woff2') format('woff2');
   //,
   //     url('../font/ProximaNova-Medium.ttf')  format('truetype'); /* Safari, Android, iOS */
    font-weight: 500;
}
@font-face {
   font-family: ProximaNova_Bold;
   src: url('../font/ProximaNova-Bold.ttf')  format('truetype'); /* Safari, Android, iOS */
  //url('../font/ProximaNova-Bold.woff2') format('woff2'),
   font-weight: 700;
}

@font-face {
    font-family: ProximaNova_SemiBold;
    src: url('../font/ProximaNova-Semibold.ttf')  format('truetype');
    // url('../font/ProximaNova-Semibold.woff2') format('woff2');
    //     /* Safari, Android, iOS */
    font-weight: 600;
}


  @font-face {
    font-family: SF_Pro_Display_Regular;
    src: url('../font/SF-Pro-Display-Regular.ttf')  format('truetype'); /* Safari, Android, iOS */
    //url('../font/SF-Pro-Display-Regular.woff2') format('woff2'),
     font-weight: 400;
     font-style: normal;
  }

  @font-face {
    font-family: SF_Pro_Display_Medium;
    src: url('../font/SF-Pro-Display-Medium.ttf')  format('truetype'); /* Safari, Android, iOS */
    //url('../font/SF-Pro-Display-Medium.woff2') format('woff2'),
    //
     font-weight: 500;
 }
 @font-face {
    font-family: SF_Pro_Display_Bold;
    src: url('../font/SF-Pro-Display-Bold.ttf')  format('truetype'); /* Safari, Android, iOS */
   //url('../font/SF-Pro-Display-Bold.woff2') format('woff2'),
    font-weight: 700;
 }
 
 @font-face {
     font-family: SF_Pro_Display_SemiBold;
     src: url('../font/SF-Pro-Display-Semibold.ttf')  format('truetype'); /* Safari, Android, iOS */
   //url('../font/SF-Pro-Display-Semibold.woff2') format('woff2'),
     font-weight: 600;
 }

 @font-face {
    font-family: SF_Pro_Display_Black;
    src: url('../font/SFProDisplay-Black.ttf')  format('truetype'); /* Safari, Android, iOS */
  //url('../font/SF-Pro-Display-Semibold.woff2') format('woff2'),
    font-weight: 800;
}
.montserrat_thin {
    font-family: montserrat_thin;
    letter-spacing: .03rem;
}

.montserrat_medium {
    font-family: montserrat_medium;
    letter-spacing: .03rem;
}

.montserrat_light {
    font-family: montserrat_thin;
    letter-spacing: .03rem;
    font-weight: bold;
}

.montserrat_regular {
    font-family: montserrat_regular;
    letter-spacing: .03rem;
}

.montserrat_semibold {
    font-family: montserrat_semibold;
    letter-spacing: .03rem;
}

.montserrat_bold {
    font-family: montserrat_bold;
    letter-spacing: .03rem;
}

@font-face {
    font-family: Poppins_regular;
    src: url('../font/Poppins-Regular.woff2');
    font-weight: 400;
}
@font-face {
    font-family: Poppins_semibold;
    src: url('../font/Poppins-SemiBold.woff2');
    font-weight: 600;
}
@font-face {
  font-family: raleway_bold;
  src: url('../font/Raleway-Bold.woff2');
}