@import "reset";
@import "mixin", "font", "utility", "variable", 'loader', 'OrgPost', 'modal';
@import "classes";
@import "buttons";
@import "resetPassword";
@import "physicianDetails";
@import "physicianNewDetails";

header,
section {
  background: #FFFFFF;
}

.loader_main {
  min-height: calc(100vh - 143px);

  @media screen and (max-width: 680px) {
    min-height: calc(100vh - 245px);
  }
}

.c_pointer {
  cursor: pointer;
}

.safari_browser {
  top: 7px;
  border-radius: 0;
  -webkit-border-radius: 0;
}

.safari_browser {
  top: 4px;
  border-radius: 0;
  -webkit-border-radius: 0;

  &::-webkit-slider-thumb {
    transition: all 0.8s linear;
    background-color: #1784E9;
  }

  &::-webkit-slider-runnable-track {
    transition: all 0.8s linear;
    border-radius: 0;
    -webkit-border-radius: 0;
  }
}

section.cg_post_data {
  padding: 10px 0 50px;
  min-height: calc(100vh - 143px);

  @media screen and (max-width: 680px) {
    min-height: calc(100vh - 245px);
    padding: 0 0 20px;
  }

  .can_container {
    @media (max-width: 767px) {
      padding: 0;
    }

    &.container_mobile_padding {
      padding: 0 !important;
    }
  }
}

.sportLightContent {
  position: relative;
  margin-bottom: 8.5px;

  &.spotlight_dotted {
    display: flex;
    justify-content: space-between;
    max-width: 372px;
    width: 100%;
    align-items: center;
  }

  label {
    font-size: 14px;
    line-height: 15px;
    font-family: SF_Pro_Display_Medium;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: $gray;
  }

  .sportLightContent_label_img {
    position: absolute;
    top: -3px;
    left: 57px;

    svg {
      width: 13px;
      height: 13px;
    }
  }

}

.mobile_slider {
  display: none;

  @media (max-width:$extra_small) {
    display: block;
  }
}

.post_img_slider {
  position: relative;

  &.img_slider_posted {
    height: 400px;
    overflow: hidden;
  }

  @media (max-width:$extra_small) {
    display: none;
  }

  &.current_post_video {
    @media (max-width:$extra_small) {
      display: block !important;
    }
  }

  .slick-disabled {
    opacity: 0 !important;
    pointer-events: none;
  }
}

.post_img_slider,
.mobile_slider {

  // .layer_text{
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   z-index: 999  ;
  //   h4{
  //     color: white !important;
  //   }
  // }
  .layer_text {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.35);
    position: absolute;
    // padding: 5px 8px;
    border-radius: 20px;
    top: 14px;
    left: 16.5px;
    z-index: 9;
    padding: 0 10px;
    transition: all 0.8s ease-in-out;

    &.layer_text_fade {
      opacity: 0;
      transition: opacity 0.9s ease;
    }

    &.layer_text_fade_out {
      opacity: 1;
    }

    h4 {
      font-family: SF_Pro_Display_Medium !important;
      font-size: 14px !important;
      line-height: 15px !important;
      color: $white !important;
      letter-spacing: 0.02em;
      font-weight: 500;
      text-transform: capitalize;
      margin-bottom: 0;
      display: inline-block;
      max-width: 97px;
      width: 100%;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      padding-top: 5px;
    }
  }

  .dotted_btn {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 9;

    span {
      padding: 7px 6.5px;
      background: rgba(0, 0, 0, 0.35);
      border: none;
      outline: none;
      border-radius: 20px;
      font-weight: 400;
      font-family: SF_Pro_Display_Regular;
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      text-transform: capitalize;
      color: #FBFBFB;
    }
  }

  .animate_default {
    overflow: hidden;
    height: 376px;

    .for_vdo_desc {
      bottom: 0px;
    }
  }

  .animate_square {
    overflow: hidden;
    height: 370.5px;
  }

  .animate_vertical {
    overflow: hidden;
    height: 666px;
  }

  .animate_img {
    overflow: hidden;
    height: 100%;
  }

  .slick-arrow {
    width: 24px;
    height: 24px;
    background: #c4c4c4;
    border-radius: 50%;
    z-index: 9;
  }

  .slick-next {
    right: 16px !important;

    &::before {
      content: '';
      position: absolute;
      background: url("../images/next_arrow.svg") no-repeat;
      width: 6.4px;
      height: 11.2px;
      top: 5px;
      left: 9px;
      z-index: 9;
    }
  }

  .slick-prev {
    left: 15px !important;

    &::before {
      content: '';
      position: absolute;
      background: url("../images/prev_arrow.svg") no-repeat;
      width: 6.4px;
      height: 11.2px;
      top: 5px;
      left: 8px;
    }
  }

  .post_slider {
    position: relative;
    cursor: pointer;

    img.mar_b.cg_post_slider {
      max-width: 100%;
      width: 100%;
      border-radius: 10px;
      height: 272px;
      object-fit: cover;

      &.user_post_img {
        max-width: 375px;
        width: 100%;
        // min-height: 400px;
        // height: 100%;
        height: 400px;
        border-radius: 0;
        margin-bottom: 0;
      }
    }

    .posted_video {
      position: relative;
      transition: all 0.8s ease-in-out;

      &:hover {

        .video_play_btn,
        .video_pause_btn {
          button {
            opacity: 1 !important;
          }
        }
      }

      .for_ratio {
        background-color: black;
        height: 376px;
        padding-top: 83px;
        padding-bottom: 82px;
      }

      .video_play_btn,
      .video_pause_btn {
        button {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 50px;
          height: 50px;
          background: rgba(62, 74, 88, 0.85);
          border-radius: 50%;
          border: none;
          outline: none;
          z-index: 9;
          cursor: pointer;
          transition: all 0.8s ease-in-out;

          svg {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 13px auto;
          }
        }
      }

      video {
        width: 100%;
        object-fit: cover;
      }
    }

    .for_fit_screen {
      bottom: 0px !important;
    }

    .volume {
      position: absolute;
      bottom: 24px;
      right: 14px;
      z-index: 99;

      button {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #636363;
        border: none;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        img {
          width: 17px;
          height: 14px;
        }
      }
    }
  }

  .progress_bar {
    position: absolute;
    bottom: -10px;
    width: 100%;

    .progress_upper {
      position: absolute;
      top: 12px;
      left: 0;
      width: 100%;
      height: 4px;
      z-index: 9;
      border-radius: 0;
      -webkit-border-radius: 0;
      transition: all 0.6s linear;
      border: 0;

      &::-webkit-progress-bar {
        background-color: #D9D9D9;
        border: 0;
        border-radius: 0;
        -webkit-border-radius: 0;
      }

      &::-webkit-progress-value {
        background-color: #1784E9;
        border: 0;
        -webkit-border-radius: 0;
        border-radius: 0;
      }

      &.safari_browser {
        border: 0 !important;
        border-radius: 0 !important;
        -webkit-border-radius: 0 !important;
      }
    }

    input {
      max-width: 375px;
      width: 100%;
      height: 4px;
      cursor: pointer;
      position: relative;
      z-index: 999;
      transition: all 0.6s linear;
      appearance: none;
      background-color: transparent;
      border-radius: 0;
      -webkit-border-radius: 0;

      &::-webkit-slider-thumb {
        width: 11px;
        height: 11px;
        transition: all 0.8s linear;
        background-color: #1784E9;
      }

      &::-webkit-slider-runnable-track {
        transition: all 0.8s linear;
        border-radius: 0;
        -webkit-border-radius: 0;
      }

      &.safari_browser {
        top: 4px !important;
        border-radius: 0 !important;
        -webkit-border-radius: 0 !important;

        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          transition: all 0.8s linear;
          cursor: pointer;
          background-color: #1784E9;
        }

        &::-webkit-slider-runnable-track {
          transition: all 0.8s linear;
          border-radius: 0;
          -webkit-border-radius: 0;
        }
      }
    }
  }

  .post_img_desc {
    max-width: 375px;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    // height: 110px;
    padding: 11px 15px;
    transition: all 0.8s ease-in-out;

    &.desc_img_slider {
      opacity: 0;
      transform: translateY(100%);
    }

    h3 {
      font-family: SF_Pro_Display_Bold;
      font-size: 20px;
      line-height: 22px;
      font-weight: 700;
      color: #E5E5E5;
      letter-spacing: 0.02em;
      max-width: 339px;
      width: 100%;
    }

    p {
      font-family: SF_Pro_Display_Regular;
      font-size: 14px;
      line-height: 18px;
      font-weight: 400;
      color: #E5E5E5;
      letter-spacing: 0.02em;
      max-width: 339px;
      width: 100%;
    }

    &.for_vdo_desc {
      padding: 18px 15px 12px 15px;
      // height: 110px;
      bottom: 6px;

      // position: relative;
      h3 {
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.02em;
        color: #E5E5E5;
        font-family: SF_Pro_Display_Black;
        font-weight: 800;
        max-width: 293px;
        width: 100%;
      }

      p {
        line-height: 16px;
        max-width: 297px;
        width: 100%;
        text-align: justify;
      }

    }
  }
}

.like_comment_btn {
  margin-top: 16.5px;
}

.can_container {
  @include container;

  @media only screen and (max-width: 1440px) {
    max-width: 1404px;
    padding: 0 20px 0 52px !important;
  }

  @media only screen and (max-width: 576px) {
    padding: 0 20px !important;
  }

  @media only screen and (max-width : $extra_small) {
    padding: 0 16px !important;
  }

  img.mar_b.cg_post_slider {
    width: 100%;
    border-radius: 10px;
    height: 272px;
    object-fit: cover;
  }
}

.cg_popup_main {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(0 0 0 / 40%);
  z-index: 9999;
}

.popup_can {
  max-width: 250px;
  height: 190px;
  position: relative;
  padding: 28px 22px 25px 22px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 15px rgb(0 0 0 / 10%);
  border-radius: 10px;
  display: block;
  visibility: visible;
  z-index: 1;

  a {
    text-decoration: none;
    cursor: pointer;

    .blue_img,
    .red_img {
      font-family: ProximaNova_Bold;
      background: #3E7496;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      letter-spacing: 0;
      margin-right: 2px;
    }

    .red_img {
      background: #D0131C;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      margin: 0;
    }

  }

  .pop_para {
    font-family: SF_Pro_Display_Medium;
    font-size: 13px;
    line-height: 17px;
    color: #3E4A58;
    margin-bottom: 21px;
  }

  .pop_close {
    @include heading;
    padding: 16px 41px 17px;
    text-align: center;
    text-transform: uppercase;
    background: #CBCBCB;
    border-radius: 30px;
    line-height: 17px;
    border: none;
    display: block;
    margin: auto;
    font-family: SF_Pro_Display_Bold;
    cursor: pointer;
    height: 47.83px;
  }

  //}

}

.header_line {
  padding: 13px 0 14px 0;

  .mainlogo {
    position: relative;

    .logo_img {
      margin-right: 25px;

      @include respond(small) {
        width: 55px;
        height: 41.74px;
        margin-right: 19px;
      }
    }
  }

  @include respond(small) {
    padding: 13px 0 14px;
  }

  .mainlogo {
    &::after {
      position: absolute;
      top: 8px;
      width: 1px;
      height: 40px;
      background: #C00C2D;
      bottom: 0;
      content: "";

      @include respond(small) {
        height: 30px;
      }
    }
  }

  .main_heading {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: #3E7496;
    max-width: 299px;
    margin: 0 0 0 25px;
    font-family: ProximaNova_SemiBold;

    @include respond(small) {
      margin-left: 20px;
      font-size: 12px;
      line-height: 14px;
      margin: 6px 0 0 25px;
    }
  }
}

.app_dwn_section {
  background: $light_gray;

  @include respond(midium) {
    display: none;
  }
}

.app_dwn {
  padding: 9px 0 8px 0;
  // flex-wrap: wrap;
  max-width: 772px;
  width: 100%;

  // &.app_padding{
  //   padding-bottom: 0;
  // }
  p {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.02em;
    color: #000000;
    margin: 8px 17px 8px 0;
    font-family: ProximaNova_SemiBold;
  }

  .applestore_button {
    max-width: 105px;
    width: 100%;
    // height: 36px;
    cursor: pointer;
    margin: 0 15px 0 0;
  }

  .playstore_button {
    max-width: 126px;
    width: 100%;
    // height: 38px;
    cursor: pointer;
  }

  img {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.main_content {
  display: flex;
  align-items: flex-start;
  margin: 4.5px 0 0 0;
  justify-content: space-between;
  max-width: 761px;

  .new_post_block {
    margin: 0 0 22px;
  }
  .new_post_block_grp {
    margin: 0 0 22px;
    padding: 9px 15px;
    background: #EDEDED;

    .i_degn_grp{
      // border: 2px solid #ffffff;
      // filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15));
      width: 32px;
      height: 32px;
      border-radius: 5px;
      margin-right: 18px;
      object-fit: cover;
    }
    .grp_name{
      font-size: 14px;
      line-height: 18px !important;
      color: #495568;
      font-family: SF_Pro_Display_Medium !important;
      margin: 0;
      width:288px;
      word-break: break-all;
      span{
        font-size: 14px;
        line-height: 18px !important;
        color: #495568;
        font-family: SF_Pro_Display_Bold;
        margin: 0;
      }
    }
  }

  .first_article {
    &.video_wrapper {
      margin-top: 0;
      max-width: 375px;
      width: 100%;
      margin-right: 0;
      padding-right: 0;
    }

    @include f_article;
    max-width: 360px;
    width: 100%;
    margin-right: 20px;
    padding-right: 20px;

    @include respond(tablet) {
      // max-width: 100%;
      padding: 0;
      max-width: 375px !important;
    }

    .f_detail {
      margin-top: 6px;
      display: block;
      width: 100%;

      .side_detail {
        width: 100%;
        padding: 0;

        .title_img_wrapper_meta_main {
          background: #E5E5E5;
          margin-bottom: 17px;
          border-radius: 5px;

          .title_img_wrapper_meta {
            max-width: 100%;
            width: 100%;
            display: block;
            height: 275px;
            margin: 0;

            img {
              max-width: 100%;
              max-height: 100%;
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
            }
          }

          .title_img_wrapper_meta_pdfDisplay{
            max-width: 100%;
            width: 100%;
            height: 100px;
            margin: 0;
            background-color: #b30b00;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
      
            img {
              max-height: 100%;
              display: block;
              height: 100%;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
            }
          }

          .meta_title {
            margin: 0;
            padding: 11px 15px;
            font-family: SF_Pro_Display_Medium;
            font-size: 14px;
            line-height: 19px;
          }
        }

        .title_img_wrapper {
          max-width: 100%;

          @media (min-width: 440px) and (max-width: 768px) {
            height: 140px;
          }
        }
      }
    }

    .view_all_sec {
      margin-left: 9px;
      align-items: center;

      .btn_view_all {
        margin-left: 11px;

        button {
          background-color: #457B9D;
          border: none;
          border-radius: 30px;
          color: #FFFFFF;
          padding: 7px 14px;
          font-family: SF_Pro_Display_Medium;
          font-size: 13px;
          width: 100%;
          cursor: pointer;
          line-height: 15px;
          letter-spacing: 0.02em;
          cursor: pointer;
        }

        &.view_all_comment {
          margin-left: 24px;
          // margin-top: 20px;
        }
      }
    }


    .main_comments_wrapper {
      display: flex;
      // background: #fbfbfb;

      .cg_postUser_detail {
        padding: 4px 0 0;
      }

      .cg_post_profile {
        max-width: 45px;
        width: 100%;

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 18px;
          // left: 22px;
          right: 0;
          width: 1px;
          height: 100%;
          top: 7px;
          z-index: -1;
          border-left: 1px solid #CED5DA;
        }
      }

      .side_detail {
        width: calc(100% - 45px);

        .para_detail {
          p {
            font-size: 15px;
            line-height: 20px;
            font-family: SF_Pro_Display_Regular;
            font-weight: 400;
            letter-spacing: 0.02em;
            color: #12151A;
          }
        }

        .para_head {
          // font-family: ProximaNova_Medium;
          font-family: SF_Pro_Display_Medium;
          font-weight: 500;
          margin: 0;
        }
      }
    }

    div:last-child .cg_post_profile::after {
      display: none;
    }

  }

  .second_article {
    @include s_article;
    padding: 14px 0 0;
    // max-height: 847px;
    max-width: 374px;
    width: 100%;
    margin-left: 20px;

    @include respond(tablet) {
      max-height: 100%;
      max-width: 100%;
      margin: 0;
      padding: 22px 0 0;
    }

    .f_detail {
      &.f_content {
        &:last-child {
          .para_detail {
            .text {
              margin-bottom: 1px;
            }
          }
        }
      }

      .side_detail {
        .para_head {
          font-family: SF_Pro_Display_Medium;
          //font-family: ProximaNova_Medium;
          margin: 0;
          font-size: 12px;
          line-height: 12px;
        }

        .para_detail {
          margin: 8px 0 0;

          p {
            margin-bottom: 28px;

            &.reademore_txt.text {
              margin-bottom: 15px;
            }
          }
        }
      }

      @media (max-width: 380px) {
        padding: 30px 15px 0;

        &::after {
          width: calc(100% - 25px);
        }
      }
    }

  }

  .third_article {
    @include third_article;

  }

  .fourth_article {
    @include third_article;

    @include respond(midium) {
      background: #FFFFFF;
      max-width: 326px;
      width: 100%;
      margin: 0 auto;
      padding: 22px 20px 50px;

      .f_head {
        @include heading;
        color: #457B9D;
      }

      .mobile_app {
        margin: 22px auto 0 auto;
      }
    }


  }

  @include respond(tablet) {
    flex-direction: column;
  }

  @include respond(midium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.cg_top_mobile_header {
  padding: 6px 20px 3px;
  border-bottom: 1px solid #D9D9D9;
  display: none;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 480px) {
    display: flex;
  }

  .cg_topheader_logo {
    max-width: 203px;
    width: 100%;
    height: 36px;
    margin-right: 10px;

    img {
      display: block;
      max-width: 100%;

    }
  }

  .cg_topheader_open {
    background: #1E94FF;
    border-radius: 30px;
    max-width: 73px;
    width: 100%;
    display: block;
    padding: 8px 10px 6px;
    font-size: 16px;
    line-height: 16px;
    color: #FFFFFF;
    font-family: ProximaNova_SemiBold;
    border: none;
    text-transform: uppercase;
    cursor: pointer;
    margin: 5px 0;
  }
}

.article_main_wrraper {

  // max-height: 550px;
  // overflow-y: auto;
  // &::-webkit-scrollbar-thumb {
  //   background: rgba(0, 0, 0, 0.3);
  // }
  // &::-webkit-scrollbar {
  //   width: 5px;
  // }
  .otherPost_title {
    font-family: SF_Pro_Display_Bold;
    //font-family: ProximaNova_Bold;
    font-size: 14px;
    line-height: 14px;
    color: #457B9D;
    text-align: center;
    margin: 0;
    padding: 0 20px;
  }

}

// ---------------footer--------
.footer_main {
  background: $light_gray;
  @include footer;
  position: fixed;
  width: 100%;
  height: auto;
  bottom: 0;
  z-index: 999;
}

.cg_add_line {
  margin: 0;
}

// .cg_add_line {
// }
.cg_post_profile {
  position: relative;
  z-index: 99;
  margin-right: 10px;
  width: 100%;
  display: flex;
  align-items: flex-start;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 18px;
    // left: 22px;
    right: 0;
    width: 1px;
    height: 100%;
    top: 7px;
    z-index: -1;
    border-left: 1px solid #CED5DA;
  }

  &.cg_post_profile_detail {
    &::after {
      display: none;
    }
  }

  .cg_postUser_detail {
    width: calc(100% - 45px);
    padding: 5px 0 0 10px;

    .time_d {
      span {
        font-family: SF_Pro_Display_SemiBold;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.02em;
      }
    }
  }

}

.psDetail-main {
  height: auto !important;
}

.see_more_details {
  margin-right: 24px;
  margin-bottom: 10px;
}

.see_more_details_Post {
  display: flex;
  justify-content: flex-end;
  font-size: 13px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  font-family: 'ProximaNova_Bold';

  a {
    color: #c00c2d;

    &:hover {
      color: #c00c2d !important;
    }
  }
}

.showmore_article .f_detail:nth-last-child(2) {
  .cg_post_profile {
    &::after {
      display: none;
    }
  }
}

.blue-color {
  color: rgba(23, 132, 233, 1) !important;
  cursor: pointer;
}

.manage_group {
  .can_container {
    &.mobile_container {
      @media (max-width : $extra_small) {
        max-width: unset !important;
        padding: 0 !important;
      }
    }

    .manage_group_banner {
      margin: 15px 0 27px 0;
      max-width: 375px;
      width: 100%;

      .manage_group_img_slider {
        .slick-arrow {
          width: 24px;
          height: 24px;
          background: #c4c4c4;
          border-radius: 50%;
          z-index: 9;
        }

        .slick-next {
          right: 16px !important;

          &::before {
            content: '';
            position: absolute;
            background: url("../images/next_arrow.svg") no-repeat;
            width: 6.4px;
            height: 11.2px;
            top: 5px;
            left: 9px;
            z-index: 9;
          }
        }

        .slick-prev {
          left: 15px !important;

          &::before {
            content: '';
            position: absolute;
            background: url("../images/prev_arrow.svg") no-repeat;
            width: 6.4px;
            height: 11.2px;
            top: 5px;
            left: 8px;
          }
        }

        .manage_group_img_slider_item {
          img {
            max-width: 375px;
            width: 100%;
            height: 205px;
            object-fit: cover;
          }
        }
        .slick-disabled {
          opacity : 0 !important; 
          pointer-events:none;
        }
      }
    }
  }

  .healing_group_desc {
    .can_container {
      .healing_group_header {
        max-width: 375px;
        width: 100%;

        h4 {
          font-family: SF_Pro_Display_Bold;
          font-size: 16px;
          line-height: 20px;
          font-weight: 700;
          letter-spacing: 0.02em;
          color: $text_gray;
          margin-bottom: 2px !important;
          text-align: justify;
        }
      }

      .private_group_content {
        max-width: 98px;
        width: 100%;
        margin-bottom: 5px;
        .private_group_icon{
        img{
           width: 18px;
           height: 18px;
         }
       }
        .private_group_txt {
          small {
            font-family: SF_Pro_Display_Medium;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.02em;
            font-weight: 500;
            color: $text_gray;
            text-transform: capitalize;
          }
        }
      }

      .administrator_lbl {
        font-family: SF_Pro_Display_SemiBold;
        font-size: 14px;
        line-height: 16px;
        font-weight: 600;
        letter-spacing: 0.02em;
        color: $text_gray;
        margin: 10px 0 14px 0;
      }

      .location_content {
        padding-top: 8px;
        max-width : 375px;
        width: 100%;
        .user_profile_pic {
          border: 2px solid #FFFFFF;

          img {
            border-radius: 50%;
            width: 28px;
            height: 28px;
            object-fit: cover;
            filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15));
          }
        }

        .users_location {
          margin-left: 10px;

          span {
            font-family: SF_Pro_Display_Medium;
            font-size: 14px;
            line-height: 17px;
            font-weight: 500;
            letter-spacing: 0.02em;
            text-transform: capitalize;
            color: $text_gray;
          }
        }
      }

      .view_group_info {
        margin: 6px 0;

        small {
          font-family: SF_Pro_Display_SemiBold;
          font-size: 14px;
          line-height: 16px;
          font-weight: 600;
          letter-spacing: 0.02em;
          color: $blue;
          cursor: pointer;
        }
      }

      .total_member {
        max-width: 375px;
        width: 100%;
        span {
          font-family: SF_Pro_Display_SemiBold;
          font-size: 14px;
          line-height: 16px;
          font-weight: 600;
          letter-spacing: 0.02em;
          color: $text_gray;
        }

        p {
          font-family: SF_Pro_Display_SemiBold;
          font-size: 11px;
          line-height: 13px;
          font-weight: 600;
          color: $light_blue;
          margin-bottom: 0;
          padding-top: 7px;
        }
      }

      .like_by {
        margin: 6px 0 15px 0;
      }

      .join_grp {

        &.request_join_group {
          max-width: 375px;
          width: 100%;
          padding: 19px 11px 19px 8px;
          background-color: #F2F2F2;
          margin: 21px 0 23px 0;
          border-radius: 5px;

          @media (max-width : $extra_small) {
            max-width: 344px;
          }
          h5{
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0.02em;
            max-width: 325px;
            width: 100%;
          }
          p{
            max-width: 310px;
          }
        }

        h5 {
          font-family: SF_Pro_Display_Medium;
          font-size: 12px;
          line-height: 16px;
          font-weight: 500;
          letter-spacing: 0.02em;
          color: $light_blue;
          max-width: 343px;
          width: 100%;
          margin-bottom: 0;
        }

        .join_btn {
          padding: 12px 0 13px 0;

          button {
            cursor: pointer;
          }
        }

        p {
          font-family: SF_Pro_Display_Medium;
          font-size: 14px;
          line-height: 19px;
          font-weight: 500;
          letter-spacing: 0.02em;
          color: $text_gray;
          max-width: 325px;
          width: 100%;
          margin-bottom: 0;
          span {
            font-weight: 700;
            font-family: SF_Pro_Display_Bold;
            color: $maroon;
          }
        }
      }
    }

  }

  .private_group_post {
    .can_container {
      .private_grp_wrapper {
        max-width: 375px;
        width: 100%;

        .private_about_group {
          h4 {
            font-family: SF_Pro_Display_SemiBold;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.02em;
            text-transform: capitalize;
            color: $text_gray;
            margin: 0 0 13px 0;
          }

          p {
            font-family: SF_Pro_Display_Regular;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0.02em;
            color: #12151A;
          }
        }

        .private_grp_categories {
          margin-top: 40px;
          .private_grp_categories_content{
              max-width: 375px;
              width: 100%;
          }
          h4 {
            font-family: SF_Pro_Display_SemiBold;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.02em;
            color: $text_gray;
          }

          .private_grp_hashtag {
            margin-top: 12px;
            display: flex;
            flex-wrap: wrap;
            span {
              font-family: SF_Pro_Display_SemiBold;
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0.02em;
              color: #12151A;
              margin: 0 12px 1px 0;
            }
          }
        }

        .private_rules_grp {
          .private_rules_grp_content {
            h4 {
              font-family: SF_Pro_Display_SemiBold;
              font-weight: 600;
              font-size: 14px;
              line-height: 16px;
              letter-spacing: 0.02em;
              text-transform: capitalize;
              color: $maroon;
              margin: 24px 0 18px 0;
            }

            .private_rules_grp_desc {
              h5 {
                font-family: SF_Pro_Display_Bold;
                font-weight: 700;
                font-size: 14px;
                line-height: 19px;
                text-transform: capitalize;
                color: $gray;
              }

              p {
                font-family: SF_Pro_Display_Regular;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                color: $black;
              }
            }
          }
        }
      }
    }

  }

  .first_article {
    max-width: 375px;
    width: 100%;
    margin: 22px 0;

    .main_comments_wrapper {
      .cg_post_profile {
        &.cg_post_profile_line {
          &::after {
            display: none;
          }
        }

        &::after {
          left: 19px !important;
          top: 22px !important;
        }
      }

      .view_all_sec {
        max-width: 156px;
        width: 100%;
        justify-content: space-between;
        margin-left: 11px;
        margin-top: 17px;
        align-items: center;

        img {
          width: 16.67px;
          height: 16.67px;
        }

        .btn_view_all {
          button {
            min-width: 120px;
            width: 100%;
            padding: 6px 10px !important;
            cursor: pointer;
          }
        }
      }
    }

    .f_detail {
      margin-top: 25px;

      .profile_line {
        max-width: 36px;
        width: 100%;
        height: 36px;
        border-radius: 50%;
        filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15));

        img {
          // width: 36px;
          // height: 36px;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }
      }
    }

    .cg_post_profile_detail {
      &::after {
        // display: none;
      }

      .img_profile {
        width: 45px;
        height: 45px;
        margin-right: 0;
      }

      .cg_postUser_detail {

        .para_head,
        .can_head {
          margin-bottom: 0;
        }

        .can_head {
          font-family: SF_Pro_Display_Bold;
          font-size: 15px;
          line-height: 20px;
          font-weight: 700;
          letter-spacing: 0.02em;
          color: $text_gray;
        }

        .para_head {
          font-family: SF_Pro_Display_Medium;
          font-size: 14px;
          line-height: 17px;
          font-weight: 500;
          letter-spacing: 0.02em;
          color: $text_gray;
          padding: 4px 0;
        }

        .time_d {
          &.time_dropdown_icon {
            display: flex;
            justify-content: space-between;
            max-width: 255px;
            width: 100%;

            img {
              cursor: pointer;
              width: 24px;
              height: 10px;
            }
          }

          span {
            font-family: SF_Pro_Display_SemiBold;
            font-size: 14px;
            line-height: 17px;
            font-weight: 600;
            letter-spacing: 0.02em;
            color: $text_gray;
          }
        }
      }
    }

    .side_detail {
      &.inner_comment_side_details {
        margin-left: 15px;
        max-width: 324px;
        width: 100%;

        .cg_postUser_detail {
          max-width: 272px;
          width: 100%;
          padding-right: 0;
        }
      }

      .post-description-main {
        font-family: SF_Pro_Display_Regular;
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        letter-spacing: 0.02em;
        color: #12151A;
        margin-top: 10px;
      }

      .cg_postUser_detail {
        padding-left: 0;

        .time_d {
          max-width: 258px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-top: 7px;

          img {
            cursor: pointer;
            width: 24px;
            height: 10px;
          }
        }

        .para_head {
          font-family: SF_Pro_Display_Medium;
          font-size: 14px;
          line-height: 17px;
          font-weight: 500;
          letter-spacing: 0.02em;
          text-transform: capitalize;
          color: $text_gray;
          margin-bottom: 0;
        }
      }

      .post-description-main {
        font-family: SF_Pro_Display_Regular;
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        letter-spacing: 0.02em;
        color: #12151A;
      }

      .icon_content {
        img {
          cursor: pointer;
        }

        .like_comment_width {
          max-width: 50px;
          width: 100%;
          justify-content: space-between;

          img {
            cursor: pointer;
          }
        }
      }
    }
  }

  .manage_group_footer_container {
    margin: 0 auto;
    max-width: 1374px;
    width: 100%;

    .manage_group_footer {
      max-width: 428px;
      width: 100%;
      height: 6px;
      background: rgba(69, 123, 157, 0.2);
      margin: 22px 0 25px 0;
    }
  }

  .write_something_post {
    padding: 14px 0;

    .manage_group_plus_btn {
      button {
        width: 32px;
        height: 32px;
        background: $maroon;
        border-radius: 50%;
        border: none;
        outline: none;
        cursor: pointer;
        position: relative;

        img {
          width: 12.8px;
          height: 12.8px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .input_for_write_post {
      span {
        margin-left: 7px;
        border: none;
        outline: none;
        max-width: 200px;
        width: 100%;
        font-family: SF_Pro_Display_Medium;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.02em;
        color: $text_gray;
      }
    }
  }

  .manage_group_footer_container {
    .write_something_post_footer {
      max-width: 428px;
      width: 100%;
      height: 2px;
      background: rgba(69, 123, 157, 0.2);
    }
  }
  .title_img_wrapper_meta_main {
    background: #E5E5E5;
    margin-bottom: 17px;
    border-radius: 5px;

    .title_img_wrapper_meta {
      max-width: 100%;
      width: 100%;
      display: block;
      height: 275px;
      margin: 0;

      img {
        max-width: 100%;
        max-height: 100%;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
    }

    .meta_title {
      margin: 0;
      padding: 11px 15px;
      font-family: SF_Pro_Display_Medium;
      font-size: 14px;
      line-height: 19px;
    }
  }
}

.manage_group {
  .can_container {
    .first_article {
      .f_detail {
        .comment_box {
          margin-top: 25px;
          margin-bottom: 19px;
          display: flex;
          align-items: center;

          &.comment_like_reply {
            margin-bottom: 18px;
          }

          small {
            padding: 0 13px 0 7px;

            &.cmnt_cnt_text {
              padding-left: 0 !important;
            }
          }

          small,
          span {
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #457b9d;
            font-family: SF_Pro_Display_SemiBold;
          }
        }

        .cg_post_profile_after {
          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 18px;
            // left: 22px;
            right: 0;
            width: 1px;
            height: 100%;
            top: 7px;
            z-index: -1;
            border-left: 1px solid #CED5DA;
          }
        }
      }
    }
  }

  .no_post_content {
    .no_post_can_container {
      max-width: 1404px;
      padding: 0 20px 0 52px !important;
      margin: 0 auto;

      @media only screen and (max-width: 576px) {
        padding: 0 20px !important;
      }

      @media only screen and (max-width : $extra_small) {
        padding: 0 16px !important;
      }

      &.mobile_container {
        @media (max-width : $extra_small) {
          max-width: unset !important;
          padding: 0 !important;
        }
      }

      .no_post_wrapper {
        .no_post_desc {
          max-width: 375px;
          width: 100%;

          h4 {
            font-family: SF_Pro_Display_Bold;
            font-size: 20px;
            line-height: 20px;
            font-weight: 700;
            text-align: center;
            color: $text_gray;
          }

          p {
            font-family: SF_Pro_Display_Regular;
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
            text-align: center;
            letter-spacing: 0.02em;
            color: $text_gray;
          }
        }

        .no_post_img {
          max-width: 375px;
          width: 100%;
          margin-top: 39px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .no_post_bg_clr{
          max-width: 375px;
          width : 100%;
          height: 250px;
          background: rgba(250,250,250,1);
          margin-top: -50px;
        }
      }
      }
    }
  }



.readMore {
  font-family: SF_Pro_Display_Bold;
  color: #C00C2D;
  cursor: pointer;
}