// Text
.text_left{text-align: left;}
.text_center{text-align: center;}
.text_right{text-align: right;}
.text_uppercase{text-transform: uppercase;}
.text_capitalize{text-transform: capitalize;}
.text_decoration_none{text-decoration: none;}

// Radius
.radius_0{border-radius: 0;}
.radius_5{border-radius: .5rem;}
.radius_6{border-radius: .6rem;}
.radius_10{border-radius: 1rem;}
.radius_20{border-radius: 2rem;}
.radius_rounded{border-radius: 100%;}

// Display Properties
.d_block{display: block;}
.d_inline_block{display: inline-block;}
.d_none{display: none;}
.d_flex{display: flex;}
.d_inline_flex{display: inline-flex;}
.align_items_center{ align-items: center;}
.align_items_end{align-items: flex-end;}
.align_items_start{align-items: flex-start;}
.justify_content_center{justify-content: center;}
.justify_content_between{justify-content: space-between;}
.justify_content_around{justify-content: space-around;}
.justify_content_evenly{justify-content: space-evenly;}
.justify_content_start{justify-content:flex-start;}
.justify_content_end{justify-content:flex-end !important;}
.d_flex_center{
  @extend .d_flex;
  @extend .align_items_center;
  @extend .justify_content_center;
}
.flex_direction_row_reverse{flex-direction: row-reverse}
.flex_direction_column{flex-direction: column;}
.nowrap{flex-wrap: nowrap;}
.flex_wrap{flex-wrap: wrap;}
.word_break{word-break: break-all;}
.white_space_nowrap{white-space: nowrap;}
.white_space_wrap{white-space: normal;}
.row{
  @extend .d_flex;
  @extend .flex_wrap;
}

.w_100 {width: 100%;}

// Positions
.position_fixed{position: fixed;}
.position_relative{position: relative;}
.position_absolute{position: absolute;}

// General
.fullWidth {float: left; width: 100%;}
.border_0{border: 0;}
.cursor_pointer{cursor: pointer;}
.cursor_initial{cursor: initial;}
.disabled{pointer-events: none;cursor: not-allowed;}
.bg_transparent{background: transparent}

// Margin - Padding
.m_0{margin: 0;}
.mt_05{margin-top: .5rem;}
.mt_1{margin-top: 1rem;}
.mt_2{margin-top: 1.5rem;}
.mt_3{margin-top: 3rem;}
.mt_4{margin-top: 4rem;}

.mb_05{margin-bottom: .5rem;}
.mb_1{margin-bottom: 1rem;}
.mb_2{margin-bottom: 1.5rem;}
.mb_3{margin-bottom: 3rem;}
.mb_4{margin-bottom: 4rem;}
.mb_60{margin-bottom: 6rem;}
.mb_12{margin-bottom: 12px;}
.ml_05{margin-left: .5rem;}
.ml_1{margin-left: 1rem;}
.ml_2{margin-left: 1.5rem;}
.ml_3{margin-left: 3rem;}
.ml_4{margin-left: 4rem;}

.mr_05{margin-right: .5rem;}
.mr_1{margin-right: 1rem;}
.mr_2{margin-right: 1.5rem;}
.mr_3{margin-right: 3rem;}
.mr_4{margin-right: 4rem;}

.mx_auto{margin: 0 auto;}

.p_0{padding: 0;}
.pt_1{padding-top: 1rem;}
.pt_2{padding-top: 1.5rem;}
.pt_3{padding-top: 3rem;}
.pt_4{padding-top: 4rem;}

.pb_1{padding-bottom: 1rem;}
.pb_2{padding-bottom: 1.5rem;}
.pb_3{padding-bottom: 3rem;}
.pb_4{padding-bottom: 4rem;}

.pl_1{padding-left: 1rem;}
.pl_2{padding-left: 1.5rem;}
.pl_3{padding-left: 3rem;}
.pl_4{padding-left: 4rem;}

.pr_1{padding-right: 1rem;}
.pr_2{padding-right: 1.5rem;}
.pr_3{padding-right: 3rem;}
.pr_4{padding-right: 4rem;}

.px_auto{padding: 0 1.5rem;}

.text_ellipse {
  //overflow: hidden;
  // //Addition lines for 2 line or multiline ellipsis
  //display: -webkit-box !important;
  //-webkit-line-clamp:16;
  //-webkit-box-orient: vertical;
  //width: 307px;
  //margin: 0;
  //white-space: pre-wrap;
  //text-overflow: initial;
  //white-space: pre-wrap;
  --line-height: 1.9;
  --lines-to-show: 13;
  box-sizing: border-box;
  line-height: var(--line-height);
  overflow: hidden;
  height: calc(1.9 * 13 * 1em);

  @media screen and (max-width: 352px) {
    --lines-to-show: 14;
  }

  //.phy_bio_wrapper{
  //  white-space: pre-wrap;
  //}
}
.view_more_btn{
  @include _view_more_btn;
}